import { FC } from 'react';
import IconProps from './IconProps';

type CommentCountIconProps = {
  count: number;
} & IconProps;

const CommentCountIcon: FC<CommentCountIconProps> = (props) => {
  const { onClick, className, count } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  return (
    <svg
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2 0.993164H4.8C3.52696 0.993164 2.30606 1.50914 1.40589 2.42759C0.505713 3.34603 0 4.59171 0 5.89059V14.053C0 15.3518 0.505713 16.5975 1.40589 17.516C2.30606 18.4344 3.52696 18.9504 4.8 18.9504H11.744L19.536 24.5171C19.666 24.6108 19.8204 24.6628 19.9796 24.6665C20.1387 24.6701 20.2953 24.6252 20.4293 24.5376C20.5633 24.45 20.6686 24.3235 20.7318 24.1745C20.795 24.0254 20.8132 23.8605 20.784 23.7009L20.104 18.8035C21.1905 18.591 22.1717 18.0021 22.8816 17.1365C23.5915 16.2708 23.9866 15.1816 24 14.053V5.89059C24 4.59171 23.4943 3.34603 22.5941 2.42759C21.6939 1.50914 20.473 0.993164 19.2 0.993164ZM22.4 14.053C22.4 14.9189 22.0629 15.7493 21.4627 16.3616C20.8626 16.9739 20.0487 17.3179 19.2 17.3179C19.0848 17.3168 18.9707 17.341 18.8656 17.389C18.7604 17.4369 18.6667 17.5075 18.5907 17.5959C18.5147 17.6842 18.4584 17.7883 18.4255 17.9009C18.3926 18.0135 18.3839 18.1321 18.4 18.2484L18.936 22.1011L12.448 17.4648C12.3165 17.3711 12.1604 17.32 12 17.3179H4.8C3.95131 17.3179 3.13737 16.9739 2.53726 16.3616C1.93714 15.7493 1.6 14.9189 1.6 14.053V5.89059C1.6 5.02467 1.93714 4.19422 2.53726 3.58192C3.13737 2.96962 3.95131 2.62564 4.8 2.62564H19.2C20.0487 2.62564 20.8626 2.96962 21.4627 3.58192C22.0629 4.19422 22.4 5.02467 22.4 5.89059V14.053Z"
        fill="#C5A76B"
      />
      <text x="50%" y="45%" dominantBaseline="middle" textAnchor="middle" fill="#C5A76B" className="text-dpm-12 font-medium">
        {count}
      </text>
    </svg>
  );
};

export default CommentCountIcon;
